<template>
  <v-container>
    <v-row class="justify-center">
      <v-data-table
        v-if="products"
        :headers="header_product"
        :items="products"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title>Produtos </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=""
                  v-on="on"
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  icon
                  x-large
                  @click="dialogNewProduct = true"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Cadastrar novo produto
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.deleted="{ item }">
          <v-icon :color="getColor(item.deleted)"
            >mdi-checkbox-marked-circle</v-icon
          >
        </template>

        <template v-slot:item.manipula_meta="{ item }">
          <v-icon :color="setColarMeta(item.manipula_meta)"
            >mdi-checkbox-marked-circle</v-icon
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            
            text
            icon
            color="teal lighten-2"
            @click="(dialogEditProduct = true), loadProduct(item)"
          >
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-btn
            
            text
            icon
            color="red lighten-2"
            @click="(dialogRemoveProduct = true), loadProduct(item)"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
    <v-divider></v-divider>
    <v-dialog
      class="justify-center"
      v-model="dialogNewProduct"
      overlay-opacity="0.9"
      max-width="auto"
      transition="dialog-transition"
    >
      <newProduct
        v-on:close-dialog="closeNewProduct"
        v-on:update-list="refreshList"
        v-if="dialogNewProduct"
      />
    </v-dialog>

    <v-dialog
      class="justify-center"
      v-model="dialogEditProduct"
      overlay-opacity="0.9"
      max-width="600px"
      transition="dialog-transition"
    >
      <editProduct
        :productSelected="product"
        v-on:close-dialog="closeEditProduct"
        v-on:update-list="refreshList"
        v-if="dialogEditProduct"
      />
    </v-dialog>
    <v-dialog
      class="justify-center"
      v-model="dialogRemoveProduct"
      overlay-opacity="0.3"
      max-width="400px"
      transition="dialog-transition"
    >
      <removeProduct
        :productSelected="product"
        v-on:close-dialog="closeRemoveProduct"
        v-on:update-list="refreshList"
        v-if="dialogRemoveProduct"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import newProduct from "./newProduct";
import editProduct from "./editProduct";
import removeProduct from "./removeProduct";
export default {
  components: {
    newProduct,
    editProduct,
    removeProduct
  },
  data: () => {
    return {
      product: null,
      products: [],
      header_product: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Referência", value: "ref_produto" },
        { text: "Meta", value: "manipula_meta" },
        { text: "Ativo", value: "deleted" },
        { text: "Ações", value: "actions" }
      ],
      dialogNewProduct: false,
      dialogEditProduct: false,
      dialogRemoveProduct: false
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      try {
        const { data } = await this.$http.get("/product");
        this.products = data.data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os produtos.");
      }
    },
    loadProduct(product) {
      this.product = product;
    },
    refreshList() {
      this.dialogNewProduct = false;
      this.dialogEditProduct = false;
      this.dialogRemoveProduct = false;
      this.getProducts();
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    setColarMeta(status) {
      if (status) return "success";
      else return "error";
    },
    closeNewProduct() {
      this.dialogNewProduct = false;
    },
    closeEditProduct() {
      this.dialogEditProduct = false;
    },
    closeRemoveProduct() {
      this.dialogRemoveProduct = false;
    }
  }
};
</script>

<style>
</style>