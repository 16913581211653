var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"justify-center"},[(_vm.products)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header_product,"items":_vm.products,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"teal",attrs:{"dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Produtos ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 mb-2",attrs:{"color":"","icon":"","x-large":""},on:{"click":function($event){_vm.dialogNewProduct = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,956593215)},[_vm._v(" Cadastrar novo produto ")])],1),_c('v-divider')]},proxy:true},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColor(item.deleted)}},[_vm._v("mdi-checkbox-marked-circle")])]}},{key:"item.manipula_meta",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.setColarMeta(item.manipula_meta)}},[_vm._v("mdi-checkbox-marked-circle")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"teal lighten-2"},on:{"click":function($event){(_vm.dialogEditProduct = true), _vm.loadProduct(item)}}},[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){(_vm.dialogRemoveProduct = true), _vm.loadProduct(item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,false,789930919)}):_vm._e()],1),_c('v-divider'),_c('v-dialog',{staticClass:"justify-center",attrs:{"overlay-opacity":"0.9","max-width":"auto","transition":"dialog-transition"},model:{value:(_vm.dialogNewProduct),callback:function ($$v) {_vm.dialogNewProduct=$$v},expression:"dialogNewProduct"}},[(_vm.dialogNewProduct)?_c('newProduct',{on:{"close-dialog":_vm.closeNewProduct,"update-list":_vm.refreshList}}):_vm._e()],1),_c('v-dialog',{staticClass:"justify-center",attrs:{"overlay-opacity":"0.9","max-width":"600px","transition":"dialog-transition"},model:{value:(_vm.dialogEditProduct),callback:function ($$v) {_vm.dialogEditProduct=$$v},expression:"dialogEditProduct"}},[(_vm.dialogEditProduct)?_c('editProduct',{attrs:{"productSelected":_vm.product},on:{"close-dialog":_vm.closeEditProduct,"update-list":_vm.refreshList}}):_vm._e()],1),_c('v-dialog',{staticClass:"justify-center",attrs:{"overlay-opacity":"0.3","max-width":"400px","transition":"dialog-transition"},model:{value:(_vm.dialogRemoveProduct),callback:function ($$v) {_vm.dialogRemoveProduct=$$v},expression:"dialogRemoveProduct"}},[(_vm.dialogRemoveProduct)?_c('removeProduct',{attrs:{"productSelected":_vm.product},on:{"close-dialog":_vm.closeRemoveProduct,"update-list":_vm.refreshList}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }