<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Remover Produto</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title> Você deseja remover este produto?</v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> o produto será removido</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text v-on:click="$emit('close-dialog')">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-btn color="success" text @click="removeProduct()">
        <v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["productSelected"],
  created() {
    this.setDataProduct();
  },
  data: () => {
    return {
      product: {
        id: null,
        name: "",
        ref_product: "",
        manipula_goal: false
      }
    };
  },
  methods: {
    setDataProduct() {
      this.product.id = this.productSelected.id;
      this.product.name = this.productSelected.nome;
      this.product.ref_product = this.productSelected.ref_produto;
      this.product.manipula_goal = this.productSelected.manipula_meta;
    },
    async removeProduct() {
      const id = this.product.id;
      const result = await this.$http.delete(`/product/${id}`);
      if (result.status == 200) {
        this.$toast.success("Produto Removido com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao Remover Produto!");
      }
    }
  }
};
</script>

<style>
</style>