<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Editar Produto</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title></v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          name="name"
          label="Nome"
          placeholder="Informe o nome do produto"
          v-model="product.name"
          :rules="nameRules"
          required
        />
        <v-text-field
          name="ref_product"
          label="Referência"
          placeholder="Informe a referência do produto"
          v-model="product.ref_product"
          :rules="refRules"
          required
        />
        <v-checkbox
          v-model="product.manipula_goal"
          :label="`Manipula Metas`"
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions class="pl-6">
      <v-btn
        color="success"
        outlined
        large
        :disabled="!valid"
        @click="updateProduct()"
        >Atualizar Produto</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["productSelected"],
  data: () => {
    return {
      valid: true,
      product: {
        id: null,
        name: "",
        ref_product: "",
        manipula_goal: false
      },
      nameRules: [v => !!v || "Name is required"],
      refRules: [v => !!v || "Ref is required"]
    };
  },
  created() {
    this.setDataProduct();
  },
  methods: {
    async updateProduct() {
      const result = await this.$http.put("/product", this.product);
      if (result.status == 201) {
        this.$toast.success("Produto Atualizado com Sucesso!");
        this.$emit("update-list");
      } else {
        this.$toast.error("Falha ao atualizar novo Produto!");
      }
    },
    setDataProduct() {
      this.product.id = this.productSelected.id;
      this.product.name = this.productSelected.nome;
      this.product.ref_product = this.productSelected.ref_produto;
      this.product.manipula_goal = this.productSelected.manipula_meta;
    }
  }
};
</script>

<style>
</style>